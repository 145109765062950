// import { createSpeechlySpeechRecognition } from "@speechly/speech-recognition-polyfill";
import List from "antd-mobile/es/components/list";
import { useCallback, useRef, useState } from "react";
// import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { v4 as uuid } from "uuid";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import InputText from "~/components/ui/input/InputText";

// const appId = "e0c261f6-1caa-4ca9-a808-f1dc89cd1883";
// const SpeechlySpeechRecognition = createSpeechlySpeechRecognition(appId);
// SpeechRecognition.applyPolyfill(SpeechlySpeechRecognition);

type AddIngredientsProps = {
  recipeId: string;
  stepId: string;
  fetcher: any;
};

export default function AddIngredients({ recipeId, stepId, fetcher, onClose, open, title, subtitle, editingIngredient }: AddIngredientsProps) {
  const [ingredients, setIngredients] = useState([uuid()]);
  const ref = useRef();

  const handleAddIngredientToList = useCallback((ing) => {
    const ingSplit = ing.split(" next");
    setIngredients((prev) => [...prev, ...ingSplit]);
    // resetTranscript();
  }, []);
  const commands = [{ command: "* next", callback: handleAddIngredientToList }];

  //need 4 inputs, one for amount, unit, ingredient, and optional comment
  // const { transcript, resetTranscript, listening, browserSupportsSpeechRecognition, isMicrophoneAvailable } = useSpeechRecognition({ commands });
  // const startListening = () => SpeechRecognition.startListening();

  // const toggleMicrophone = useCallback(() => {
  //   if (listening) {
  //     // SpeechRecognition.stopListening();
  //   } else {
  //     resetTranscript();
  //     startListening();
  //   }
  // }, [listening]);

  const addIngredient = () => {
    setIngredients((prev) => [...prev, uuid()]);
  };

  const removeIngredient = (id) => {
    setIngredients((prev) => prev.filter((i) => i !== id));
  };

  const handleSubmitForm = useCallback(() => {
    const formdata = new FormData(ref.current);
    const formProps = Object.fromEntries(formdata);
    fetcher.submit(formdata, { method: "post", action: "/api/recipe/step" });
    onClose();
  }, [fetcher]);

  // if (!browserSupportsSpeechRecognition) {
  //   return <span>Browser doesn't support speech recognition.</span>;
  // }

  // if (!isMicrophoneAvailable) {
  //   return <span>Please allow access to the microphone</span>;
  // }

  return (
    <>
      <PopupWithNavbar
        open={open}
        onClose={onClose}
        title={title}
        subtitle={subtitle}
        headerClass="bg-theme-300"
        actions={[
          // {
          //   key: "delete",
          //   text: <TrashEmptyIcon className="h-4 w-4" />,
          //   buttonClassName: "flex-none",
          //   color: "danger",
          //   onClick: () => removeIngredient(ing),
          // },
          {
            key: "submit",
            text: "Submit",
            color: "primary",
            onClick: handleSubmitForm,
          },
        ]}
      >
        <div className="flex flex-1 flex-col overflow-y-auto">
          <fetcher.Form className={"nested-list sticky-items flex flex-1 flex-col"} ref={ref}>
            <input type="hidden" name="action" value={"add-step-ingredient"} />
            <input type={"hidden"} name={"uuid"} value={editingIngredient?.id ?? uuid()} />
            <input type="hidden" name="recipeId" value={recipeId} />
            <input type="hidden" name="stepId" value={stepId} />
            <input type="hidden" name="editingIngredient" value={editingIngredient && JSON.stringify(editingIngredient)} />
            {/* <p>Microphone: {listening ? "on" : "off"}</p>
      <div className="flex flex-row">
        <div className="flex flex-1">
          <InputText
            className="flex flex-1 font-medium leading-6 text-gray-900"
            name={`nlp`}
            title={"Ingredient"}
            value={transcript}
            withLabel={false}
            placeholder={"Ingredient"}
            borderless={true}
            setValue={setNlp}
            // ref={inputRef}
          />
        </div>
        <button onClick={toggleMicrophone}>Click to {listening ? "Stop" : "Start"} Listening</button>
      </div> */}
            {/* <div className="flex flex-row">Ingredients: {JSON.stringify(ingredients)}</div> */}
            {/* <List className="w-full bg-gray-100">
                <List.Item className="bg-gray-100" title={"Quantity"} />
                <List.Item className="">
                  <InputText
                    className="flex flex-1 font-medium leading-6 text-gray-900"
                    name={`ing_amount`}
                    title={"Amount"}
                    value={"1"}
                    withLabel={false}
                    placeholder={"Amount"}
                    borderless={true}
                    disabled={true}
                    type={"number"}
                    // ref={inputRef}
                  />
                </List.Item>
                <List.Item className="bg-gray-100" title={"Unit"} />
                <List.Item className="">
                  <InputText
                    className="flex flex-1 font-medium leading-6 text-gray-900"
                    name={`fake2`}
                    title={"Unit"}
                    value={"cup"}
                    withLabel={false}
                    placeholder={"Unit"}
                    borderless={true}
                    disabled={true}
                    // ref={inputRef}
                  />
                </List.Item>
                <List.Item className="bg-gray-100" title={"Ingredient"} />
                <List.Item className="">
                  <InputText
                    className="flex flex-1 font-medium leading-6 text-gray-900"
                    name={`fake3`}
                    title={"Ingredient"}
                    value={"all-purpose flour"}
                    withLabel={false}
                    placeholder={"Ingredient"}
                    borderless={true}
                    disabled={true}
                    // ref={inputRef}
                  />
                </List.Item>
                <List.Item className="bg-gray-100" title={"Note"} />
                <List.Item className="">
                  <InputText
                    className="flex flex-1 font-medium leading-6 text-gray-900"
                    name={`fake4`}
                    title={"Comment"}
                    value={"sifted"}
                    withLabel={false}
                    placeholder={"Comment"}
                    borderless={true}
                    disabled={true}
                    // ref={inputRef}
                  />
                </List.Item>
              </List> */}

            {ingredients.map((ing, index) => {
              return (
                <List key={ing} className="w-full bg-gray-100">
                  <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Quantity"} />
                  <List.Item className="">
                    <InputText
                      className="flex flex-1 font-medium leading-6 text-gray-900"
                      name={`amount`}
                      title={"Amount"}
                      withLabel={false}
                      placeholder={"Amount"}
                      borderless={true}
                      type={"number"}
                      defaultValue={editingIngredient?.quantity}
                    // ref={inputRef}
                    />
                  </List.Item>
                  <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Unit"} />
                  <List.Item className="">
                    <InputText
                      className="flex flex-1 font-medium leading-6 text-gray-900"
                      name={`unit`}
                      title={"Unit"}
                      withLabel={false}
                      placeholder={"Unit"}
                      borderless={true}
                      defaultValue={editingIngredient?.unit}
                    // ref={inputRef}
                    />
                  </List.Item>
                  <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Ingredient"} />
                  <List.Item className="">
                    <InputText
                      className="flex flex-1 font-medium leading-6 text-gray-900"
                      name={`ingredient`}
                      title={"Ingredient"}
                      withLabel={false}
                      placeholder={"Ingredient"}
                      borderless={true}
                      defaultValue={editingIngredient?.ingredient}
                    // ref={inputRef}
                    />
                  </List.Item>

                  <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Note"} />
                  <List.Item className="">
                    <InputText
                      className="flex flex-1 font-medium leading-6 text-gray-900"
                      name={`comment`}
                      title={"Comment"}
                      withLabel={false}
                      placeholder={"Comment"}
                      borderless={true}
                      rows={2}
                      defaultValue={editingIngredient?.comment}
                    // ref={inputRef}
                    />
                  </List.Item>

                  {/* <div className="flex flex-none">
                    <Button color="danger" onClick={() => removeIngredient(ing)}>
                      <TrashEmptyIcon className="h-4 w-4" />
                    </Button>
                  </div> */}
                </List>
              );
            })}
            {/* {true ? null : <Button onClick={addIngredient}>Add Ingredient</Button>} */}
          </fetcher.Form>
        </div>
      </PopupWithNavbar>
    </>
  );
}
