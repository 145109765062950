import { forwardRef } from "react";
import ChevronDownIcon from "~/components/ui/icons/ChevronDownIcon";

export default forwardRef(function Accordion(
  { title, children, open, onClick, showArrow = true }: { title: any; children: any; open?: boolean; onClick?: () => void; showArrow?: boolean },
  ref
) {
  //   const [expanded, setExpanded] = useState(defaultOpen ?? false);
  //   const toggleExpanded = () => setExpanded((current) => !current);

  //   useImperativeHandle(
  //     ref,
  //     () => {
  //       return {
  //         toggleExpanded,
  //         open: () => setExpanded(true),
  //         close: () => setExpanded(false),
  //         isOpen: expanded,
  //       };
  //     },
  //     []
  //   );

  return (
    <div className="cursor-pointer">
      <div className="flex select-none flex-row items-center justify-between text-left" onClick={onClick}>
        <div className="flex-1">{title}</div>
        {showArrow && (
          <div className="flex-none px-2 text-gray-400">
            {open ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4 rotate-180" />}
          </div>
        )}
      </div>
      <div className={`overflow-hidden pt-0 transition-[max-height] scrollbar-hide ease-in ${open ? "max-h-96" : "max-h-0"}`}>{children}</div>
    </div>
  );
});
