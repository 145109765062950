import { useFetcher, useMatches } from "@remix-run/react";
import { Group as CheckboxGroup } from "antd-mobile/es/components/checkbox/group";
import List from "antd-mobile/es/components/list";
import Tag from "antd-mobile/es/components/tag";
import clsx from "clsx";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import { useCookRecipeSelectorContext } from "~/components/context/cookRecipeContext";
import { usePantrySelectorContext } from "~/components/context/pantryContext";
import { useShoppingListSelectorContext } from "~/components/context/shoppingListContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import PlusIcon from "~/components/ui/icons/PlusIcon";
import EditIcon from "~/components/ui/icons/crud/EditIcon";
import InputText from "~/components/ui/input/InputText";
import { useRootData } from "~/utils/data/useRootData";
import AddIngredients from "./AddIngredients";
import Ingredient, { IngredientCheckbox } from "./Ingredient";
import { closeConfirmModal, confirmModal } from "./confirmModal";

type StepIngredientsProps = {
  ingredients: any[];
  step: number;
  ratio: number;
  allIngredients?: any[];
  recipeName?: string;
  stepText?: string;
  stepId?: string;
  recipeId?: string;
  editable?: boolean;
  onCheckboxClick?: (val: boolean, ingredientId: string) => void;
  afterEditDescription?: (description: string) => void;
  hideOnSmall?: boolean;
};

export default forwardRef(function StepIngredients(
  {
    stepText,
    ingredients,
    step,
    ratio,
    allIngredients,
    recipeName,
    stepId,
    recipeId,
    editable = false,
    onCheckboxClick,
    afterEditDescription,
    hideOnSmall = false,
    ingredientsHidden = false,
  }: StepIngredientsProps,
  ref
) {
  const [editOpen, setEditOpen] = useState(false);
  const ingredientsChecked = useCookRecipeSelectorContext((state) => state.ingredientsChecked);

  const handleAddOneOrAllToShoppingList = useShoppingListSelectorContext((state) => state.handleAddOneOrAllToShoppingList);

  const handleAddToPantry = usePantrySelectorContext((state) => state.handleAddToPantry);

  const [editIngredientsOpen, setEditIngredientsOpen] = useState(false);
  const [editingIngredient, setEditingIngredient] = useState(null);
  const originalValue = ingredients?.map((ingredient) => ingredient.id);
  const [textValue, setTextValue] = useState(stepText ?? "");
  const [value, setValue] = useState(ingredients?.map((ingredient) => ingredient.id));
  const fetcher = useFetcher();
  const ingredientFetcher = useFetcher();
  const matches = useMatches();

  const onRecipe = useMemo(() => {
    return matches?.find((match) => match.id === "routes/app.$tenant/recipe/$urlId.$urlKey");
  }, [matches]);

  const currentRecipe = useMemo(() => {
    if (onRecipe) {
      return {
        ...onRecipe.data?.item,
      };
      return {};
    }
  }, [onRecipe]);

  const includedIngredients = ingredients;

  const [recipeIngredients, setRecipeIngredients] = useState(allIngredients ?? currentRecipe?.ingredients);

  const ingredientsInStep = useMemo(() => {
    return recipeIngredients?.filter((ingredient) => value?.includes(ingredient.id));
  }, [recipeIngredients, value]);
  const ingredientsNotInStep = useMemo(() => {
    return recipeIngredients?.filter((ingredient) => !value?.includes(ingredient.id));
  }, [recipeIngredients, value]);

  const handleAddShopping = useCallback(
    (ingredient) => {
      handleAddOneOrAllToShoppingList(ingredient, recipeId);
    },
    [recipeId]
  );

  const handleEditAddShopping = useCallback(
    (e, ingredient) => {
      e.stopPropagation();
      handleAddOneOrAllToShoppingList(ingredient, recipeId);
    },
    [recipeId]
  );
  const rootData = useRootData();
  const openAccount = useAccountSelectorContext((state) => state.openAccount);

  const handleAddIngredient = useCallback((e, ingredient) => {
    e && e.stopPropagation();
    handleOpenIngredients();
    setEditingIngredient(ingredient);
  }, []);

  const ingredientLeftActions = useMemo(() => {
    return [
      {
        key: "pantry",
        text: "In Pantry",
        color: "primary",
        onClick: rootData.hasSub ? handleAddToPantry : openAccount,
      },
    ];
  }, [handleAddToPantry, rootData.hasSub]);

  const editIngredientLeftActions = useMemo(() => {
    return [];
  }, []);
  const ingredientRightActions = useMemo(() => {
    return [
      {
        key: "shop",
        text: "Add to Shopping List",
        color: "warning",
        onClick: rootData.hasSub ? handleAddShopping : openAccount,
      },
    ];
  }, [handleAddShopping, rootData.hasSub]);

  const editIngredientRightActions = useMemo(() => {
    return [
      {
        key: "edit",
        text: "Edit",
        color: "warning",
        onClick: rootData.hasSub ? handleAddIngredient : openAccount,
      },
    ];
  }, [handleAddIngredient, rootData.hasSub]);

  // const ingredientEditRightActions = [{ key: "edit", text: "Edit", color: "warning" }];
  // const ingredientEditLeftActions = [{ key: "delete", text: "Delete", color: "danger" }];
  const openEdit = useCallback(() => {
    if (rootData.hasSub) {
      setEditOpen(true);
    } else {
      openAccount("popup", "edit_ingredient");
    }
  }, [rootData.hasSub]);
  const closeEdit = () => {
    setEditOpen(false);
    closeConfirmModal();
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        openEdit: openEdit,
        description: textValue,
      };
    },
    []
  );

  const handleCloseIngredients = useCallback(() => {
    setEditIngredientsOpen(false);
    closeConfirmModal();
  }, []);

  const handleOpenIngredients = useCallback(() => {
    setEditIngredientsOpen(true);
  }, []);

  const updateIngredient = useCallback(
    (ingredient) => {
      const newRecipeIngredients = [...recipeIngredients];
      const recipeIndex = newRecipeIngredients.findIndex((i) => i.id === ingredient.id);
      if (recipeIndex !== -1) {
        newRecipeIngredients[recipeIndex] = ingredient;
        setRecipeIngredients(newRecipeIngredients);
      } else {
        setRecipeIngredients((prev) => [...prev, ingredient]);
      }
    },
    [includedIngredients, recipeIngredients]
  );

  useEffect(() => {
    if (ingredientFetcher.formData) {
      const { formData } = ingredientFetcher;
      const { amount, comment, ingredient, unit, uuid } = Object.fromEntries(formData);
      const newIngredient = {
        id: uuid,
        comment,
        ingredient,
        unit,
        quantity: amount,
      };
      updateIngredient(newIngredient);
    }
  }, [ingredientFetcher]);

  // Delete Ingredient from the Recipe and make sure it's not in the step
  const handleDeleteIngredient = useCallback((e, ingredient) => {
    e.stopPropagation();
    closeConfirmModal();
  }, []);

  const handleSave = useCallback(() => {
    const form = new FormData();
    form.set("ingredients", JSON.stringify(value));
    form.set("step", stepId);
    form.set("recipeId", recipeId);
    form.set("text", textValue);
    if (afterEditDescription) {
      afterEditDescription(textValue);
    }
    fetcher.submit(form, {
      method: "post",
      action: "/api/recipe/step",
    });
    console.log("save");

    closeEdit();
    closeConfirmModal();
  }, [textValue, value, stepId]);

  const handleClose = useCallback(() => {
    if (JSON.stringify(originalValue) != JSON.stringify(value) || textValue != stepText) {
      confirmModal({
        title: "Unsaved Changes",
        content: "You have unsaved changes. Are you sure you want to close?",
        actions: [
          [
            { key: "close", text: "Close", danger: true, onClick: closeEdit },
            {
              key: "save",
              text: "Save",
              onClick: handleSave,
              primary: true,
              // style: { fontWeight: "bold" },
              // className: "text-black",
            },
          ],
        ],
      });
    } else {
      closeEdit();
    }
  }, [originalValue, value, textValue, stepText]);

  let ingredientCollapse = includedIngredients?.map((ingredient, idx) => {
    const ingredientAdjusted = { ...ingredient, quantity: ingredient.quantity * ratio };
    const modifiedLeftActions = ingredientLeftActions?.map((action) => {
      return { ...action, onClick: () => action.onClick(ingredientAdjusted) };
    });
    const modifiedRightActions = ingredientRightActions?.map((action) => {
      return { ...action, onClick: () => action.onClick(ingredientAdjusted) };
    });
    return <Ingredient key={idx} ingredient={ingredientAdjusted} leftActions={modifiedLeftActions} rightActions={modifiedRightActions} />;
  });
  if (editable) {
    ingredientCollapse = (
      <CheckboxGroup value={ingredientsChecked}>
        {includedIngredients?.map((ingredient, idx) => {
          const ingredientAdjusted = { ...ingredient, quantity: ingredient.quantity * ratio };
          // const modifiedLeftActions = ingredientLeftActions.map((action) => {
          //   return { ...action, onClick: () => action.onClick(ingredientAdjusted) };
          // });
          // const modifiedRightActions = ingredientRightActions.map((action) => {
          //   return { ...action, onClick: () => action.onClick(ingredientAdjusted) };
          // });
          return (
            <IngredientCheckbox
              key={ingredient.id}
              ingredient={ingredientAdjusted}
              // leftActions={modifiedLeftActions}
              // rightActions={modifiedRightActions}
              onChange={onCheckboxClick}
            />
          );
        })}
      </CheckboxGroup>
    );
  }

  return (
    <>
      {!ingredientsHidden && (
        <List
          className={clsx("swipable-collapse", hideOnSmall && "sm:hidden")}
          // header={
          //   <div className="space-between flex items-center">
          //     <div className="flex-1">{`Step ${step} Ingredients`}</div>
          //     <div className="flex flex-none cursor-pointer items-center justify-center" onClick={openEdit}>
          //       <span>edit</span> <DotsVerticalFilledIcon className="h-full w-3" />
          //     </div>
          //   </div>
          // }
          style={{ "--font-size": "1rem", "--header-font-size": "1rem", "--border-top": "none", "--border-bottom": "none" }}
        >
          {ingredientCollapse}
        </List>
      )}
      <PopupWithNavbar
        headerClass="bg-theme-300"
        open={editOpen}
        onClose={handleClose}
        title={`Editing Step ${step}`}
        subtitle={recipeName ?? currentRecipe?.name}
        actions={[
          {
            key: "save",
            text: "Save",
            color: "primary",
            onClick: handleSave,
          },
        ]}
      >
        <div className="flex flex-1 flex-col overflow-y-auto">
          <div className="nested-list sticky-items flex flex-1 flex-col">
            <List className="w-full bg-gray-100">
              <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Instruction"} />
              <List.Item className="">
                <InputText
                  className="flex flex-1 font-medium leading-6 text-gray-900"
                  name={`title`}
                  title={"Title"}
                  defaultValue={stepText}
                  withLabel={false}
                  placeholder={"Title"}
                  borderless={true}
                  setValue={setTextValue}
                  rows={3}
                // onEnter={handleAddStep}
                // ref={ref}
                />
              </List.Item>
              <List.Item
                className="sticky top-0 z-10 bg-gray-100"
                title={`Step ${step} Ingredients`}
                extra={
                  <div onClick={handleAddIngredient}>
                    <PlusIcon className="h-4 w-4" />
                  </div>
                }
              />
              <List.Item className="checkbox-group nested-list">
                <CheckboxGroup
                  value={value}
                  onChange={(val) => {
                    setValue(val);
                  }}
                >
                  {ingredientsInStep?.map((ingredient) => {
                    const modifiedLeftActions = editIngredientLeftActions?.map((action) => {
                      return { ...action, onClick: (e) => action.onClick(e, ingredient) };
                    });
                    const modifiedRightActions = editIngredientRightActions?.map((action) => {
                      return { ...action, onClick: (e) => action.onClick(e, ingredient) };
                    });
                    return (
                      <IngredientCheckbox
                        ingredient={ingredient}
                        key={ingredient.id}
                        leftActions={modifiedLeftActions}
                        rightActions={modifiedRightActions}
                        extra={
                          <>
                            {!originalValue?.includes(ingredient.id) && <Tag>Added</Tag>}
                            <div onClick={(e) => handleAddIngredient(e, ingredient)}>
                              <EditIcon className="h-4 w-4" />
                            </div>
                          </>
                        }
                      />
                    );
                  })}
                </CheckboxGroup>
              </List.Item>
              <List.Item
                className="sticky top-0 z-10 bg-gray-100"
                title={"Other Recipe Ingredients"}
                extra={
                  <div onClick={handleAddIngredient}>
                    <PlusIcon className="h-4 w-4" />
                  </div>
                }
              />
              <List.Item className="checkbox-group nested-list">
                <CheckboxGroup
                  value={value}
                  onChange={(val) => {
                    setValue(val);
                  }}
                >
                  {ingredientsNotInStep?.map((ingredient) => {
                    const modifiedLeftActions = editIngredientLeftActions?.map((action) => {
                      return { ...action, onClick: (e) => action.onClick(e, ingredient) };
                    });
                    const modifiedRightActions = editIngredientRightActions?.map((action) => {
                      return { ...action, onClick: (e) => action.onClick(e, ingredient) };
                    });
                    return (
                      <IngredientCheckbox
                        ingredient={ingredient}
                        key={ingredient.id}
                        leftActions={modifiedLeftActions}
                        rightActions={modifiedRightActions}
                        extra={
                          <>
                            {originalValue?.includes(ingredient.id) && <Tag>Removed</Tag>}

                            <div onClick={(e) => handleAddIngredient(e, ingredient)}>
                              <EditIcon className="h-4 w-4" />
                            </div>
                          </>
                        }
                      />
                    );
                  })}
                </CheckboxGroup>
              </List.Item>
              <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Timer"} extra={"Coming Soon"} />
              <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Tools"} extra={"Coming Soon"} />
              <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Settings"} extra={"Coming Soon"} />
            </List>
          </div>
        </div>
      </PopupWithNavbar>
      <AddIngredients
        recipeId={recipeId}
        stepId={stepId}
        fetcher={ingredientFetcher}
        onClose={handleCloseIngredients}
        editingIngredient={editingIngredient}
        open={editIngredientsOpen}
        title={"Add Ingredient"}
        subtitle={recipeName ?? currentRecipe?.name}
      />
    </>
  );
});
